import React, {useEffect, useState} from "react";


import Layout from "./layout";
import HouseOne from "../assets/picture/hp/house.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleRight, faEnvelope, faLink, faMobileScreen} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";

type resultProps = {
    globals: {}
}

const ApartmentPage = ({ globals }: resultProps ) => {
    const { container } = ApartmentPage.styles;
    const [data, setData] = useState({
        page_appartement_title: '',
        page_appartement_sub_title: '',
        page_appartement_text: ''
    });
    useEffect(()=> {
        axios.get(process.env.REACT_APP_BACKEND_URL + "/page/13").then(response => {
            const dataObj = {page_appartement_title: '',
                page_appartement_sub_title: '',
                page_appartement_text: ''};
            response.data[0].pageContent.map((x:any)=>{
                // @ts-ignore
                dataObj[x.key] = x.value
            })
            setData(dataObj);
        }).catch(error => console.log(error))
    }, [])

    return(
        <Layout>
            <div className="container mx-auto px-4 lg:mt-16 mb-16">
                <div className="flex justify-center items-center">
                    <div className="col-span-4">
                        <div className="w-full max-w-3xl mt-5 p-8 bg-white rounded-lg shadow-xl">
                            <h1 className="text-xl md:text-2xl xl:text-2xl  2xl:text-4xl font-bold border-l-8	primary-border pl-2">
                                {data.page_appartement_title}
                            </h1>
                            <div className="p-2">
                                <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-1000 font-bold">
                                    {data.page_appartement_sub_title}
                                </p>
                                <p className="mt-4 md:text-xs xl:text-sm  2xl:text-base text-gray-900 italic">
                                    {data.page_appartement_text}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*<div className="lg:mt-16">
                        <div className="flex w-full max-w-3xl mt-5 bg-white rounded-lg shadow-xl">
                            <div className="w-full sm:w-6/12">
                                <img src={HouseOne} alt="House's logo" />
                            </div>
                            <div className="text-center w-full sm:w-6/12 sm:text-left sm:flex align-middle	place-items-center	items-center">
                                <div>
                                    <h2
                                        className="text-xl md:text-xl xl:text-xl 2xl:text-2xl font-bold w-full text-gray-900"
                                    >
                                        Home 2 Home
                                    </h2>
                                    <div className="primary-main mt-4 text-white w-full">
                                        <button
                                            type="submit"
                                            className="w-full lg:w-auto  focus:ring-4 focus:outline-none focus:ring-igemo-accent font-medium text-sm px-5 py-2.5 text-center content-end h-12 self-center "
                                        >
                                            Boek een meeting
                                        </button>
                                    </div>


                                    <a href="https://h2hreno.be/" target="_blank">
                                        <div className="flex mt-4">
                                            <h2
                                                className="primary-text font-bold no-underline hover:underline cursor-pointer">
                                                Of bezoek deze site
                                            </h2>
                                            <FontAwesomeIcon
                                                icon={faCircleRight as IconProp}
                                                className="w-5 h-5 ml-1 mt-1 -m primary-text cursor-pointer	"
                                            />
                                        </div>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
        </Layout>

    )
};

ApartmentPage.styles = {
    container: {
        marginTop: '12%',
    },
};

export default ApartmentPage;
